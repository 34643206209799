import classNames from 'classnames';
import {
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type ComponentType,
  forwardRef,
  type ReactElement,
  type ReactNode,
  type SVGProps,
} from 'react';
import Skeleton from 'react-loading-skeleton';

import { type ButtonVariant } from './buttonVariant';
import styles from './button.module.scss';

export type ButtonProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'prefix'> & {
  Icon?: ComponentType<SVGProps<SVGSVGElement>>;
  LinkComponent?: ComponentType<AnchorHTMLAttributes<HTMLAnchorElement>>;
  children?: ReactNode;
  className?: string;
  dataTestId?: string;
  download?: string;
  isExternal?: boolean;
  isInverted?: boolean;
  isLoading?: boolean;
  isSmall?: boolean;
  isUppercase?: boolean;
  prefix?: ReactElement;
  suffix?: ReactElement;
  target?: string;
  to?: string;
  variant?: ButtonVariant;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      Icon,
      LinkComponent,
      children,
      className,
      dataTestId,
      disabled,
      download,
      isExternal = false,
      isInverted,
      isLoading = false,
      isSmall,
      isUppercase,
      onClick,
      prefix,
      suffix,
      target,
      to,
      type = 'button',
      variant,
      ...props
    },
    ref
  ) => {
    if (isLoading) {
      return <Skeleton height={40} width={160} {...(Icon && { circle: true, width: 40 })} />;
    }

    const PARENT_CLASSNAME = 'button';

    const sharedButtonProps = { ...props, 'data-testid': dataTestId, disabled, onClick, ref, type };

    if (Icon) {
      return (
        <button
          {...sharedButtonProps}
          className={classNames(className, styles[`${PARENT_CLASSNAME}--icon`])}
        >
          <Icon />
        </button>
      );
    }

    const buttonClasses = classNames([
      styles[PARENT_CLASSNAME],
      {
        [styles[`${PARENT_CLASSNAME}--${variant}`]]: variant,
        [styles[`${PARENT_CLASSNAME}--small`]]: isSmall,
        [styles[`${PARENT_CLASSNAME}--uppercase`]]: isUppercase,
        [styles[`${PARENT_CLASSNAME}--inverted`]]: isInverted,
        [styles[`${PARENT_CLASSNAME}--has-prefix`]]: prefix !== undefined,
        [styles[`${PARENT_CLASSNAME}--has-suffix`]]: suffix !== undefined,
      },
      className,
    ]);

    const content = (
      <>
        {prefix && <div className={styles[`${PARENT_CLASSNAME}__prefix`]}>{prefix}</div>}
        {children}
        {suffix && <div className={styles[`${PARENT_CLASSNAME}__suffix`]}>{suffix}</div>}
      </>
    );

    const sharedLinkProps = {
      ...(props as AnchorHTMLAttributes<HTMLAnchorElement>),
      className: buttonClasses,
      'data-testid': dataTestId,
      download,
      href: to,
      target,
    };

    return isExternal ? (
      <a {...sharedLinkProps}>{content}</a>
    ) : to && LinkComponent !== undefined ? (
      <LinkComponent {...sharedLinkProps}>{content}</LinkComponent>
    ) : (
      <button {...sharedButtonProps} className={buttonClasses}>
        {content}
      </button>
    );
  }
);
